import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useCalendars() {
  const calendar = ref();
  const router = useRouter();

  const getCalendars = async (page) => {
    return await ApiService.query("calendar_travels",page);
  };

  const getCalendar = async (id) => {
    let response = await ApiService.get("calendar_travels/" + id);

    calendar.value = response.data.data;
  };

  const destroyCalendar = async (id) => {
    await ApiService.delete("calendar_travels/" + id);
  };

  const stoerCalendar = async (data) => {
    await ApiService.post("calendar_travels", data);
    await router.push({ name: "apps.travels.calendar-travel.index" });
  };

  const updateCalendar = async (data) => {
    await ApiService.put("calendar_travels/" + data.id, data);
    await router.push({
      name: "apps.travels.calendar-travel.edit",
      params: { id: data.id },
    });
  };

  

  return {
    getCalendars,
    getCalendar,
    calendar,
    destroyCalendar,
    stoerCalendar,
    updateCalendar,
  };
}
